import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "h-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_navigation_loading_spinner = _resolveComponent("page-navigation-loading-spinner")
  const _component_router_view = _resolveComponent("router-view")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_ctx.state.loading)
      ? (_openBlock(), _createBlock(_component_page_navigation_loading_spinner, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_router_view)
  ]))
}