
import PageNavigationLoadingSpinner from '@/components/PageNavigationLoadingSpinner.vue';
import { computed, defineComponent, ref } from 'vue';
import store from '@/store';

export default defineComponent({
  components: {
    PageNavigationLoadingSpinner
  },
  setup() {
    const studentsRequest = ref(
      computed(() => store.getters['studentModule/studentsRequest'])
    );
    const studentRequest = ref(
      computed(() => store.getters['studentModule/studentRequest'])
    );
    const studentEventsRequest = ref(
      computed(() => store.getters['studentModule/studentEventsRequest'])
    );

    const state = ref({
      loading: computed(() => {
        return (
          studentsRequest.value.loading ||
          studentRequest.value.loading ||
          studentEventsRequest.value.loading
        );
      })
    });

    return {
      state
    };
  }
});
